import styled from "styled-components";
import Divider from "./Divider";
import DefaultContainer from "./DefaultContainer";

const Container = styled.footer`
  display: flex;
  justify-content: center;
  background-color: rgb(249, 249, 249);
  padding: 100px 0;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const BrandName = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

const PrivacyPolicy = styled.a`
  font-size: 18px;
  font-weight: 500;
  color: black;
  text-decoration: none;
`;

const Informations = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const Information = styled.div`
  color: rgb(153, 153, 153);
`;

const Footer = () => {
  return (
    <Container>
      <DefaultContainer>
        <Contents>
          <Header>
            <BrandName>팔로잉모먼츠</BrandName>
            <PrivacyPolicy href="/privacy-policy">
              개인정보처리방침
            </PrivacyPolicy>
          </Header>
          <Divider />
          <Informations>
            <Information>대표자: 박종우</Information>
            <Information>
              주소: 경기도 용인시 기흥구 강남서로 9(아카데미프라자) 7층 703호
              N52호
            </Information>
            <Information>사업자번호: 624-01-03208</Information>
            <Information>통신판매업신고: 2024-용인기흥-0606</Information>
            <Information>사업종류: 도매 및 소매업</Information>
            <Information>종목: 전자상거래 소매 중개업</Information>
          </Informations>
        </Contents>
      </DefaultContainer>
    </Container>
  );
};

export default Footer;

import styled from "styled-components";
import DefaultContainer from "./DefaultContainer";

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(245, 245, 245, 1);
  height: 100vh;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.div`
  font-size: 48px;
  font-weight: 500;
`;

const Description = styled.div`
  font-size: 20px;
  line-height: 1.3;
`;

const Preparing = styled.div`
  font-size: 16px;
`;

const AppImage = styled.img`
  width: 350px;
  height: 903px;
  object-fit: contain;
`;

const LatteLoveSection = () => {
  return (
    <Container>
      <DefaultContainer>
        <Contents>
          <Text>
            <Title>라떼러브</Title>
            <Description>
              원하는 카페에서, 원하는 시간에, 원하는 방식으로
              <br />
              새로운 사람을 만나보세요!
              <br />
              새로운 인연이 시작될 지도 모르잖아요?
              <br />
              <br />
              라떼러브는 '카페'라는 일상 속의
              <br />
              편안한 공간과 분위기 속에서 개최되는 미팅을 통해,
              <br />
              새로운 사람들을 만날 수 있는 경험을 제공합니다.
            </Description>
            <Preparing>출시를 준비 중이에요</Preparing>
          </Text>
          <AppImage src="/images/latte-love.png" />
        </Contents>
      </DefaultContainer>
    </Container>
  );
};

export default LatteLoveSection;

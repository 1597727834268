import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Title = styled.div`
  font-size: 48px;
  font-weight: 500;
`;

const SubTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
`;

const TitleSection = () => {
  return (
    <Container>
      <Contents>
        <Title>팔로잉 모먼츠</Title>
        <SubTitle>홈페이지 준비 중이에요</SubTitle>
        <SubTitle>앱 설명은 아래에 있어요</SubTitle>
      </Contents>
    </Container>
  );
};

export default TitleSection;

import styled from "styled-components";

interface Props {
  name: string;
  children?: React.ReactNode;
  height?: string;
}

const Container = styled.section<{ height?: string }>`
  height: ${(props) => (props.height ? props.height : "100vh")};
  scroll-snap-align: start;
`;

const Section = (props: Props) => {
  const { name, height, children } = props;

  return (
    <Container id={name} height={height}>
      {children}
    </Container>
  );
};

export default Section;

import styled from "styled-components";

const Container = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: white;
`;

const Bi = () => {
  return <Container>팔로잉모먼츠</Container>;
};

export default Bi;

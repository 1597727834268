import { Children } from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const Container = styled.main`
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  max-height: 100vh;
`;

const Background = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-image: url("https://images.unsplash.com/photo-1604882406305-67a5b1376126?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  filter: grayscale(100%) brightness(70%);
`;

const Main = (props: Props) => {
  const { children } = props;
  return (
    <>
      <Background />
      <Container>{children}</Container>
    </>
  );
};

export default Main;

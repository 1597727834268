import styled from "styled-components";
import Navigation from "./Navigation";
import Bi from "./Bi";
import DefaultContainer from "./DefaultContainer";

const Container = styled.header`
  mix-blend-mode: difference;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
`;

const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
`;

const Header = () => {
  return (
    <Container>
      <DefaultContainer>
        <Contents>
          <Bi />
          <Navigation />
        </Contents>
      </DefaultContainer>
    </Container>
  );
};

export default Header;

import Header from "./component/Header";
import Footer from "./component/Footer";
import Main from "./component/Main";
import Section from "./component/Section";
import TitleSection from "./component/TitleSection";
import LatteLoveSection from "./component/LatteLoveSection";

function App() {
  return (
    <div>
      <Header />
      <Main>
        <Section name="image">
          <TitleSection />
        </Section>
        <Section name="latte-love">
          <LatteLoveSection />
        </Section>
        <Section name="footer" height="100%">
          <Footer />
        </Section>
      </Main>
    </div>
  );
}

export default App;

import styled from "styled-components";

const Container = styled.nav`
`;

const Navigation = () => {
  return <Container>
  </Container>
}

export default Navigation;